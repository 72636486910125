<template>
  <card :id="cardId" />
</template>

<script>
export default {
  components: {
    card: () => import("./card"),
  },
  props: {
    phone: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      cardId: null,
    };
  },
  watch: {
    phone() {
      if (this.phone) this.getCardByPhone(this.phone);
    },
  },
  created() {
    if (this.phone) this.getCardByPhone(this.phone);
    if (this.$route.params.phone) this.getCardByPhone(this.$route.params.phone);
  },
  methods: {
    async getCardByPhone(phone) {
      this.loading = true;
      const response = await this.$axios.g(
        this.$root.api.cardGetByPhone + "/" + phone
      );
      this.loading = false;
      if (response.data.status == "ok") {
        this.cardId = response.data.data;
      }
    },
  },
};
</script>